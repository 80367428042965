/* You can add global styles to this file, and also import other style files */
@import './themes.scss';

// @import './assets/css/animate.min.css';

// @import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
// @import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

/* Importing Bootstrap SCSS file. */ 

 @import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */

// @import "~ngx-bootstrap/datepicker/bs-datepicker";

/* Toaster */
// @import '~ngx-toastr/toastr';

/*Seconday font*/

// %font2 {
//     font-family: $secondary-font;
// }


// Header Start
:host {
    display: block;
    position: relative;
}

.navbar-light {
    &.mynavbar {
        background-color: $primary-color;
        border-bottom: 1px solid transparent;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        margin-top: 0px;
        transition: all 0.3s linear;
        min-height: 64px;
        @media (max-width: 1199px) {
            background-color: $primary-color !important;
        }
        .navbar-brand {
            padding-top: 0.18rem;
            padding-bottom: 0.18rem;
            img{
                transition: height 0.3s linear;
                @media (max-width: 380px) {
                    height: 22px;
                }
            }
        }
        .nav-item {
            margin: 0 10px;
            .nav-link {
                color: $text-white;
                font-size: 15px;
                font-weight: 500;
                position: relative;
                cursor: pointer;
                span{
                    position: relative;  
                    &::after{
                        content: '';
                        width: 0px;
                        height: 1px;
                        background-color: $text-white;
                        position: absolute;
                        left: 50%;                        
                        bottom: -10px;
                        transform: translateX(-50%);
                        transition: all 0.3s linear;
                        transform-origin: center;
                    }
                }
                &.active,
                &:hover {
                    color: $text-white;                    
                    span {                                            
                         &::after {
                            width: 30px;
                        }
                    }
                }
            }
        }
        .form-inline {
            max-width: 35%;
            width: calc(35% - 40px);
            margin: 0px 15px;
            .input-group {
                background-color: $text-white;
                border-radius: 5px;
                width: 100%;
                border: 1px solid transparent;
                .form-control {
                    background-color: transparent;
                    border-color: transparent;
                    border-radius: 5px 0 0 50px;
                    color: $text-black;
                    font-size: 14px;
                    height: 38px;
                    border-right: 0px;
                    box-shadow: none;
                    outline: none;
                    &::placeholder {
                        color: #B1B4B2;
                    }
                }
                .input-group-text {
                    background-color: transparent;
                    border-color: transparent;
                    border-left: 0px;
                    border-radius: 0px 30px 30px 0px;
                }
                &:focus-within {
                    border-color: $primary-color;
                }
            }
        }        
    }
    .navbar-text {
        padding: 0.25rem 0;
    }
    .navbar-toggler {
        border: none;
        background: transparent !important;
    }
    .navbar-toggler:hover {
        background: transparent !important;
    }
    .navbar-toggler .icon-bar {
        width: 22px;
        height: 2px;
        border-radius: 1px;
        background-color: rgba(255, 255, 255, 0.5);
        display: block;
        margin-bottom: 4px;
        transition: all 0.2s;
    }
    .navbar-toggler .top-bar {
        transform: rotate(45deg);
        transform-origin: 10% 10%;
    }
    .navbar-toggler .middle-bar {
        opacity: 0;
    }
    .navbar-toggler .bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 10% 90%;
    }
    .navbar-toggler.collapsed .top-bar {
        transform: rotate(0);
    }
    .navbar-toggler.collapsed .middle-bar {
        opacity: 1;
    }
    .navbar-toggler.collapsed .bottom-bar {
        transform: rotate(0);
    }
}

.btn-primary.btnheader {
    background: $primary-gradient-color;
    color: $text-white;
    border: 0px solid transparent;
    border-radius: 5px;
    padding: 7px 15px;
    min-width: 142px;
    line-height: 25px;
    transition: all 0.3s linear;
    position: relative;    
}

.btn-primary.btnheader:hover,
.btn-primary.btnheader.active,
.btn-primary.btnheader:focus {
    background: transparent;
    border-color: transparent !important;
    color: $text-white !important;
   &::before {
       content: "";
       position: absolute;
       top: 0;
       left: 0;
       right: 0;
       bottom: 0;
       border-radius: 5px; 
       margin: -2px;
       border: 2px solid transparent;
       background: $border-color-gradient border-box;
       -webkit-mask:
         linear-gradient(#fff 0 0) padding-box, 
         linear-gradient(#fff 0 0);
       -webkit-mask-composite: destination-out;
       mask-composite: exclude;
     }
}

.btn-outline-primary.btnheader {
    border-radius: 10px;
    font-weight: 400;
    font-size: 15px;
    padding: 6px 24px;
    line-height: 26px;
    border: 1.5px solid $text-black;
    &:hover {
        color: $text-white;
        border-color: $primary-color;
        img {
            filter: brightness(0)invert(1);
        }
    }
}

.btn.btnheaderIcon {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    min-width: auto !important;
    padding: 0;
    line-height: 35px;
    border: 1px solid transparent;
    background: transparent;
    transition: all 0.3s linear;
    &:hover {
        background-color: transparent;
        border-color: $primary-color;
    }
}


.btn.btn-white{
    background-color: $text-white;
    color: $text-color13;
    border-radius: 0px;
    border: 1px solid transparent;
    padding: 7px 15px;
    line-height: 26px;
    min-width: 123px;
    height: 42px;
    font-weight: 500;
    font-size: 15px;
    outline: unset;
    box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.20);
    transition: all 0.3s linear;
    &:hover, &.active, &:focus{
        outline: unset;
        border: 1px solid $text-white;
        background-color: transparent;
        color: $text-white !important;
        box-shadow: none;
    }
    &.btn-12342
    {
        min-width: 123px;
    }
}


body.navbgset{
    .mynavbar{
        background-color: $primary-color !important;
        animation: tophead 0.3s forwards linear;
        .navbar-brand{
            img{
              //  height: 22px;
            }
        }
    }
}


@keyframes tophead {
    from{
        transform: translateY(-70px);
    }
    to{
        transform: translateY(0px);
    }
}


.dropdown-toggle::after{
    display: none;
}

.btndropdown-custom{
    background: $primary-gradient-color;
    color: $text-white;
    border: 0px solid transparent;
    border-radius: 5px;
    padding: 7px 15px;
    min-width: 142px;
    line-height: 25px;
    transition: all 0.3s linear;
    position: relative;   
    &:hover{
        background: transparent;
    border-color: transparent !important;
    color: $text-white !important;
   &::before {
       content: "";
       position: absolute;
       top: 0;
       left: 0;
       right: 0;
       bottom: 0;
       border-radius: 5px; 
       margin: -2px;
       border: 2px solid transparent;
       background: $border-color-gradient border-box;
       -webkit-mask:
         linear-gradient(#fff 0 0) padding-box, 
         linear-gradient(#fff 0 0);
       -webkit-mask-composite: destination-out;
       mask-composite: exclude;
     }
    }
}

.dropdownlist-custom{
    background: $body-bg;
    border: 1px solid $body-bg;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px; 
    width: 100%;
    .dropdown-item{
        color: $text-white;
        font-weight: 400;
        font-size: 14px; 
        &:hover{
            color: $text-white;
            background: $primary-gradient-color;
        }
    }
}
// Header End


// Footer Start
footer {
    font-size: 14px;
    line-height: 25px;
    color: $text-white;
    position: relative;
    background-color: $footerbg;	  
    .Subscribebox{
        background: linear-gradient(0deg, #1733E1 0%, #1733E1 100%), #181818;
        padding: 40px 40px;
        margin-top: -80px;
        @media (max-width: 767px) {
            padding: 20px 20px;
        }
        .jourEcoTit{
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
        }
        .jnourecoTxt{
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; 
        }
    }
    .footerSec {
        padding: 50px 0px 30px;
    }
    .footerTxt {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
        color: #E9E9E9;      
    }
    .footerTitle {        
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: $text-white;
        margin-bottom: 24px;
    }
    .footerList {
        margin-bottom: 0px;
        li {
            margin-bottom: 15px;
            a {
                color: rgba($color: #ffffff, $alpha: 0.7);
                font-size: 15px;
                font-weight: 400;
                text-decoration: none;
                &:hover {
                    font-weight: 400;
                    color: $text-white;
                    text-decoration: none;
                }
            }
        }
    }
    .footerinput {
        background-color: $text-white;
        border-color: transparent;
        color: $text-color13;
        border-radius: 0px;
        font-size: 15px;
        font-weight: 500;
        &::placeholder {
            color: #4A4A4A;
            font-weight: 400;
        }
        &:focus {
            border-color: $primary-color;
            box-shadow: none;
        }
    }
    .socialmediaLinkbtn {
        display: block;
        margin-bottom: 20px;
        text-align: center;
        &:hover {
            img {
                filter: drop-shadow(1px 0px 2px $primary-color) grayscale(0.5);
            }
        }
    }
    .Footersocials {
        div {
            display: inline-block;
            a {
                margin-right: 10px;
                margin-bottom: 10px;
                display: block;
                width: 32px;
                height: 32px;
                line-height: 32px;
                border-radius: 50%;
                background-color: rgba($color: #ffffff, $alpha: 0.08);
                text-align: center;
                color: #9B9D9F;
                font-size: 18px;
                border: 0px solid transparent;
                
                &:hover {
                    background-color: $primary-color;
                    // background-image: $primary-color;
                    color: $text-white;    
                    svg{
                        [fill="none"]{
                            fill: #ffffff;
                        }
                        [stroke="white"]{
                            stroke: #ffffff;
                        }
                    }                
                }
            }
        }
    }
    .Footer-bottom {
        .secondfooter {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid rgba(255, 255, 255, 0.21);
            font-size: 14px;
            font-weight: 300;
            color: rgba(255, 255, 255, 0.8);
        }
    }
}

.btn-black{
	height: 42px;
	&:hover{
		border-color: $text-white;
		color: $text-white;
	}
}
// Footer End



// Homepage Start
.homepage {
    position: relative;
    &::before{
    content: '';
        position: absolute;
        inset:0;
        top: 50px;
        background-image: url('../images/bannerline-bg.svg');        
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: cover;
        aspect-ratio: 1600 / 758;
        pointer-events: none;
    }
    &::after {
        content: '';
        background: linear-gradient(0deg, #1228B2 0%, #1228B2 100%), #000;        
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: -100px;
        z-index: -1;
        background-size: cover;
        background-position: center top;
        // max-height: 800px;        
    }
}

.bannersmallTit{
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    color: $text-white;
    margin-bottom: 24px;
    line-height: 120%;
    @media (max-width: 600px) {
        font-size: 25px;
    }
}

.banTit{
    // font-size: 80px;
    // font-style: normal;
    // font-weight: 700;
    //line-height: 80px;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #FFFFFF;    
    margin-bottom: 24px;
    // @media (max-width: 767px) {
    //     font-size: 30px;
    //     list-style: 32px;        
    // }
    @media (max-width: 600px) {
        font-size: 25px;
    }
}

.banner-subtit{
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; 
    color: $text-white;
    margin-bottom: 24px;
}

.banner-text{
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    color: $text-white;
}


.scrollbtn{
    background: $text-white;
    border: 0px;
    filter: drop-shadow(0px 14px 24px rgba(0, 0, 0, 0.12));
    border-radius: 100%;
    width: 60px;
    height: 60px;
    margin-top: 10px;
    transition: all .3s ease;
    svg{
        animation: moveupdown 1s linear infinite alternate;
    }
}
@keyframes moveupdown {
    0% {
        transform: translateY(4px);
    }
    
    100% {
        transform: translateY(-4px);
    }
}

.secpad-top{
    padding-top: 70px;
}

.sectit{
    .metapark-txt{
        color: $primary-color;        
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 4.2px;
    }
    .sec-mainName{
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        color: $text-color13;
        span.fw-500{
            font-weight: 500;
        }
    }
}

.secpara{
color: $text-color;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 27px; 
}

.secpad{
    padding-top: 70px;
    padding-bottom: 70px;
}
.secpad-y-40{
    padding-top: 40px;
    padding-bottom: 40px;
}
.secpadbottom{
    padding-bottom: 150px;
}
.darkbg{
    background-color: #181818;
}

.light-white{
    color: #E9E9E9;
}

.graybg{
    background: #EFEFEF;
}

.hmecardslide{
    border-radius: 0;
    background-color: $text-white;
    border: 1px solid transparent;
    min-height: 317px;    
    box-shadow: 0px 7px 24px 0px rgba(0, 0, 0, 0.05);
    &:hover{
        border-color: $primary-color;
    }
    .card-body{
        padding: 35px 30px;
    }
    .iconbgbx-sm{
        width: 34px;
        height: 34px;
        text-align: center;      
        line-height: 34px;  
        background: #F1F1F1;
        margin-bottom: 25px;
        img{
            width: auto !important;
            display: inline-block;
        }
    }
    .cardcnt{
        color: $text-color;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
    }
}

.ecosystemSlide{
    .owl-theme .owl-nav{
        margin-top: 25px;
        text-align: left;
        [class*=owl-]{
            width: 52px;
            height: 52px;
            border-radius: 0%;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(255, 255, 255, 1);                
            padding: 0;
            line-height: 52px;
            font-size: 12px;
            text-align: center;
            margin: 0;
            margin-right: 10px;
            transition: all 0.5s linear;
            &:hover{
                background-color: $text-color13;
                border-color: $text-color13;
                img{
                    filter: brightness(1)invert(1);
                }
            }
        }
    }
}
.bannerSlide{
    .owl-carousel .owl-item img{
        display: inline-block;
        width: auto;
    }
    .owl-theme .owl-dots{
        margin-top: 0px !important;
    }
    .owl-theme .owl-dots .owl-dot span{
        width: 10px;
        height: 2px;
        border-radius: 0px;
        background-color: rgba(255, 255, 255, 0.3);
        transition: width 0.3s linear;
    }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
        width: 16px;
        background-color: $text-white;
    }
}


.bgline{
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    // @media (max-width: 1400px) {
    //     padding-top: 40px;
    //     padding-bottom: 40px;
    // }
 &::before{
    content: '';
    background: url('../images/team-bgline.svg');
    background-repeat: no-repeat;
    background-position: left top;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    aspect-ratio: 802 / 370;
    height: auto;
    width: 100%;
    background-size: cover;
 }   
}

.blogcard{
    background-color: $text-white;
    margin-bottom: 20px;
    .blogcnt{
        padding: 20px;
        .blogtit{
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; 
            color: $text-color13;
            margin-bottom: 15px;
        }
        .blogpara{
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; 
            color: $text-color;
            margin-bottom: 16px;
        }
        .blog-footer{
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; 
            color: #6F6F6F;
        }
    }
}


.scrollbtnsec{
    transform: translateY(30px);
    margin-top: -30px;
}

.btn:hover .arrowmove{
    animation: arrowmove 0.5s linear 0.1s infinite alternate;
}

@keyframes arrowmove {
    from{
        transform: translateX(-5px);
    }
    to{
        transform: translateX(5px);
    }
}

.imgcircle{
    &::before{
        content: '';
        width: 460px;
        height: 420px;        
        background: #2947FF;
        filter: blur(33px);
        position: absolute;
        top: 50%;
        bottom: 0;
        left: 50%;
        right: 0;
        transform: translate(-50%, -50%);
        border-radius: 50%;        
    }
}
a.btn-black{
    line-height: 36px;
}
// Homepage End



html{
    scroll-behavior: smooth;
}

body{
    scroll-behavior: smooth !important;
    --bs-body-font-family: 'D-DIN-PRO';
    font-family: D-DIN-PRO,sans-serif !important;
}
a{
    text-decoration: none;
    &:hover{
        text-decoration: none;
    }
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}


.btn:focus,
.form-control:focus {
	box-shadow: none !important;
    outline: none !important;

}
.no-cursor{
    pointer-events: none;
    cursor: default;
}
.cur-pointer{
    cursor: pointer;
}
.btn,
.form-control {
	outline: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
}

// custom scroll
.scroller::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 10px;
}

.scroller::-webkit-scrollbar-thumb {
    background-color: $body-bg;
    /* outline: 1px solid #1e202a; */
    border-radius: 10px;
}

.scroller::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 4px rgba(0,0,0,0.2); */
    background-color: transparent;
}

@-moz-document url-prefix() {
    .scroller {
        scrollbar-width: thin;
        scrollbar-color: $body-bg;
    }
}

.text-black {
    color: $text-black;
}
.text-gray {
    color: $text-gray;
}
.text-color {
    color: $text-color;
}
.text-color13 {
    color: $text-color13;
}

// .light-text{
//     color: $light-text;
// }
// .light-text2{
//     color: $light-text2;
// }


.text-red {
    color: $text-red;
}
.greentxt,
.text-green {
    color: $text-green;
}

.btn {
    font-size: 15px;
    font-weight: 500;
    &.mw-auto {
        min-width: auto !important;
    }
    &.btn-h52{
        height: 52px !important;
        padding: 11px 15px !important;
    }
}

.btn-primary {
    background: $primary-color;
    color: $text-white;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 9px 15px;
    min-width: 150px;
    line-height: 25px;
    transition: all 0.3s linear;
    position: relative;
    &:hover {
         background: transparent;
         border-color: $primary-color !important;
         color: $primary-color !important;
        // border-image: $primary-gradient-color 1;
        // border-image-width: 2px;        
        // &::before {
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     right: 0;
        //     bottom: 0;
        //     border-radius: 5px; 
        //     margin: -2px;
        //     border: 2px solid transparent;
        //    // background: $border-color-gradient border-box;
        //     -webkit-mask:
        //       linear-gradient(#fff 0 0) padding-box, 
        //       linear-gradient(#fff 0 0);
        //     -webkit-mask-composite: destination-out;
        //     mask-composite: exclude;
        //   }
    }
    &.btn-lg {
        height: 54px;
    }
   
}

.btn-outline-primary {
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    padding: 5px 15px;    
    min-width: 160px;
    color: $text-white;
    border: 2px solid transparent;
    position: relative;
    line-height: 26px;
    // &::before {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     border-radius: 5px; 
    //     margin: -2px;
    //     border: 2px solid transparent;
    //   //  background: $border-color-gradient border-box;
    //     -webkit-mask:
    //       linear-gradient(#fff 0 0) padding-box, 
    //       linear-gradient(#fff 0 0);
    //     -webkit-mask-composite: destination-out;
    //     mask-composite: exclude;
    //   }
    &:hover {
       // background: $primary-gradient-color padding-box;
        color: $text-white;
        border-color: transparent;        
        .arrowimg{
            animation: btnarrowmove 0.5s ease-in-out infinite;
        }
    }
    &.btn-lg {
        height: 60px;
    }
}

.btn-outline-light{
    background: transparent;
    color: $text-color;
    border: 1px solid $border-color;
    border-radius: 5px;
    padding: 9px 15px;
    min-width: 150px;
    line-height: 25px;
    transition: all 0.3s linear;
    &:hover{
        background-color: $body-bg;
        color: $text-color;
    }
}
.btn.btn-white{
    background: $text-white;
    color: $text-color13;
    border: 1px solid $text-white;
    border-radius: 0px;
    padding: 6px 15px;
    min-width: 150px;
    line-height: 26px;
    transition: all 0.3s linear;    
    box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.20);
    &:hover{
        background-color: transparent;        
        color: $text-white !important;
        svg path{
            stroke: $text-white;
        }
    } 
}

.btn.btn-black{
    background: $text-color13;
    color: $text-white;
    border: 1px solid $text-color13;
    border-radius: 0px;
    padding: 6px 15px;
    min-width: 150px;
    line-height: 26px;
    transition: all 0.3s linear;    
    box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.20);
    &:hover{
        background-color: transparent;        
        color: $text-color13;
        svg path{
            stroke: $text-color13;
        }
    }
    &.v2:hover
    {
        border-color: $text-white;
        color: $text-white;
    }
}

.imgiconbtn{
    img{
        filter: brightness(0)invert(1);
    }
    &:hover{
        img{
            filter: none;
        }
    }
}

.iconbtn{
    border: 0;
    padding: 0;
    background-color: transparent;
    &:hover{
        svg{
            [stroke="#292D32"], [stroke="#2A2A2A"]{
                stroke: $primary-color;
            }
            [fill="#2A2A2A"]{
                fill: $primary-color;
            }
        }
    }        
}

.iconsvgWhite{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    &:hover{
        svg [stroke="white"]{
            stroke: $primary-color;
        }
    }
}

.maincontent {
    min-height: calc(100vh - 389px);
    padding-top: 67px;
}

.pageContent {
    padding: 50px 0px 50px;
}

.sec-pad {
    padding: 60px 0px 0px;
}

.secHead {
    font-size: 26px;
    font-weight: 500;
    // color: $text-black;
    text-align: center;
    margin: 10px auto 35px;
    position: relative;
    // &::after{
    //     content: '';
    //     position: absolute;
    //     width: 28px;
    //     height: 4px;
    //     background: $primary-color;
    //     bottom: -15px;
    //     text-align: center;
    //     left: 0;
    //     right: 0;
    //     margin: 0 auto;
    // }
}

.text-black {
    color: $text-black;
}


.mypills {
    .nav-pills {
        // justify-content: center;
        // margin-bottom: 30px;
        // border: 1px solid $border-color;
       // background-color: $light-bg;
        // border-radius: 5px;
        // padding: 0px 15px;
        .nav-item {
            margin-right: 30px;
            .nav-link {
                border: 2px solid transparent;
                color: $text-color;
                font-size: 16px;
                font-weight: 500;
                padding: 10px 0px;
                border-radius: 0;
                position: relative;
                &.active {
                    background: transparent;
                    border-bottom-color: $primary-color;
                    color: $primary-color;
                }
            }
        }
    }
}

.myverticalTabs{
    .nav-pills {
        .nav-link{
            color: $text-color;
            margin-bottom: 20px;
            padding: 0px;
            &.active{
                background-color: transparent;
                color: $primary-color;
            }
        }
    }
    @media (max-width: 991px) {
        .nav-pills {
            flex-direction: row !important;
            border-bottom: 1px solid $border-color;
            margin-bottom: 20px;
            .nav-link{
                color: $text-color;
                margin-bottom: 0px;
                padding: 10px 15px;
                margin-right: 10px;
                border-radius: 0;
                border-bottom: 1px solid transparent;
                &.active{
                    background-color: transparent;
                    color: $primary-color;
                    border-bottom: 1px solid $primary-color;
                }
            }
        }
    }
}

.myaccordion {
    .panel {
        .card {
            margin-bottom: 15px;
            border-radius: 0px;
            border-color: transparent;
            background: transparent;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            .card-header {
                background: transparent;
                border-radius: 0px;
                border: 0;
                border-bottom: 0px solid rgba(255, 255, 255, 0.1);
                padding: 0;
                button {
                    padding: 0.5rem 0rem;
                    // font-size: 16px;
                    // font-weight: 400;
                    // color: $text-black;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 60px;
                    .acctit {
                        max-width: 100%;
                        text-align: left;
                        font-weight: 400;
                        font-size: 18px;
                        color: $text-white;
                    }
                    .accicon {
                        &.round{
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            display: inline-block;
                            background: linear-gradient(180deg, #5539ED 0%, #17C5BB 100%);
                            transition: all 0.3s linear;
                            transform-origin: center;
                            margin-right: 20px;
                            &.lg-round{
                                width: 11px;
                                height: 11px;
                            }
                        }
                    }
                    &:focus {
                        box-shadow: none;
                        outline: none;
                    }
                }
            }
            .card-body {                
                padding: 0rem 0rem 1.5rem 2.5rem;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: $text-color;                
                width: 90%;
                p {
                    font-size: 14px;
                    color: $text-color;
                    font-weight: 400;
                    margin-bottom: 10px;
                    &:first-child, &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
        &.panel-open {
            .card {
                // border-color: $primary-color;
                .card-header {
                    button {                        
                        .acctit {
                        // color: $primary-color;
                        // border-bottom: 1px solid $primary-color;
                         }
                        // .accicon {
                        // color: $text-color-50;
                        // }
                    }
                }
            }
        }
    }
    // &.faqmyaccordion {
    //     .panel {
    //         .card {
    //             border-radius: 0px;
    //             padding: 0px 0px;
    //         }
    //     }
    // }
    // &:hover {
    //     border-color: $primary-color;
    // }
}

img{
    &.w-30img{
        width: 30px;
        height: 30px;
    }
    &.w-24img{
        width: 24px;
        height: 24px;
    }
    &.w-22img{
        width: 22px;
        height: 22px;
    }    
}

.formInputs {
    label {
        font-size: 14px;
        font-weight: 400;
    }
    .col-form-label {
        font-size: 14px;
        font-weight: 400;
    }
    .form-control {
        height: calc(1.6em + 1rem + 2px);
        background-color: $text-white;
        border-color: $border-color;
        box-shadow: none !important;
        color: $text-color;
        font-size: 14px;
        font-weight: 400;
        border-radius: 6px;
        &:focus {
            border-color: $primary-color;
            background-color: transparent;
            box-shadow: none !important;
            color: $text-white;
        }
        &::placeholder {
            color: $text-gray;
        }
        &.form-control-lg {
            height: 52px;
        }
        &.roundedInput {
            border-radius: 30px;
        }
    }
    .input-group {
        .input-group-text {
            background-color: transparent;
            border-color: $border-color;
            color: $text-black;
        }
        &.iconinput {
            border: 1px solid $border-color;
            border-radius: 6px;
            //background-color: $light-bg;
            .form-control {
                border-color: transparent;
                border-radius: 6px;
                background-color: transparent;
            }
        }
        &:focus-within {
            border-color: $primary-color;
            background-color: transparent;
        }
        &.lgn-group{
            padding: 10px 0px;
            border: 1px solid #EDEFF7;
            border-radius: 5px;
            height: 45px;
            .form-control {
                border: 0px;
                padding: 0px 15px;
                height: auto;
                background-color: transparent;
            }

            .input-group-text {
                background-color: transparent;
                border: 0px;
                color: $text-black;
                padding: 3px 15px;
                border-right: 2px solid #EDEFF7;                
            }
            .input-group-append{
                .input-group-text{
                    border: 0px;
                }
            }

            &:focus-within {
                border-color: $primary-color;
                background-color: transparent;
            }
        }
    }

    textarea {
        height: auto !important;
        resize: none;
    }
    select {
        appearance: none;
        // background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="rgb(255 255 255)" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.62 5.22083L7.81667 9.02416C7.36751 9.47333 6.63251 9.47333 6.18334 9.02416L2.38 5.22083' stroke='%23292D32' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");    
        background-position: center right 15px;
        background-repeat: no-repeat;
        padding-right: 40px;
    }
    &.walletInput{
        .form-control{
            background-color: #3B306A;
            border: 1px solid #615695;
            border-radius: 5px;
            color: $text-white;
            &:focus{
                border-color: $primary-color;
            }
            &::placeholder {
                color: #ADA1CC;
            }
        }
    }
}

.dropdown-toggle::after{
    display: none;
}

.mymodal {
    .modal-content{
        background: #FFFFFF;
        border: 1px solid #F4F5FA;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        .modal-header{
            border-color: 0;
            border-bottom: 1px solid #EDEFF6;
        }
        .mymodaltitle{
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;                    
        }
        .close{
            color: $primary-color;
            opacity: 0.8;
            text-shadow: none;
            &:hover{
                opacity: 1;
                svg {
                    [stroke="#292D32"]{
                        stroke: $primary-color;
                    }
                }
            }
        }
    }
}

.modal-backdrop.show{
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
    opacity: 1;
}


.btnsecondary{
  //  background: $secondary-color;
    border: 1px solid $border-color;
    border-radius: 6px;
    font-weight: 400;
    font-size: 14px; 
    padding: 8px 15px;   
}

.img-dropdownlist{
   // background: $secondary-color;
    border: 1px solid $border-color;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px; 
    width: 100%;
    .dropdown-item{        
        font-weight: 400;
        font-size: 14px; 
        &:hover{
            color: $text-white;
            background-color: $primary-color;
        }
    }
}


.cusm-progressbar{
    .progress{
        height: 9px;
        background: rgba(255, 255, 255, 0.06);
        border: 1px solid rgba(255, 255, 255, 0.09);
        backdrop-filter: blur(58px);
        border-radius: 22px;
        .progress-bar{
            background: linear-gradient(93.21deg, #BB00FF -4.32%, #00E0FF 100%);
            border-radius: 50px;
        }
    }
    
}

.card.mycard{
    background: $text-white;
    border: 1px solid #F3F3F3;
    border-radius: 10px;
    .cardheader{
        border-bottom: 1px solid #F0EDED;
    }
}

.custom_checkbox {
    margin-bottom: 5px;
    &.custom-checkbox {
      .custom-control-label {
        font-size: 14px;
        font-weight: 400;
        //color: $text-black;
        padding-top: 2px;
        padding-left: 10px;
        &::before,
        &::after {
            border: 1.5px solid #EDEFF7;
            border-radius: 4px; 
          //background-color: $text-white;
          width: 19px;
          height: 19px;
          top: 0.1rem;
        }
      }
      .custom-control-input:checked ~ .custom-control-label {
        // color: $text-color;
        &::after {
          //background-image: url("assets/images/checkTick.png");
          border-color: $primary-color;
          // top: 4px;
          // left: -1.45rem;
        }
      }
    }
  }




// Maintenance Page  
.pagetit{
    font-size: 50px;
    font-weight: 400;
    color: #131313;
    @media (max-width: 767px) {
        font-size: 30px;
    }
}


// Privacy Page
.bannerPage{
    position: relative;
    .bannerset{
        background: url('../images/privacybanner.png');
        mix-blend-mode: luminosity;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        // aspect-ratio:  1600 / 446;
    }
    .pageTitle{        
        position: relative;
        margin: 80px auto;
       &::before{
        content: '';
        background: linear-gradient(0deg, rgba(18, 40, 178, 0.8) 0%, rgba(18, 40, 178, 0.8) 100%), #000;
        mix-blend-mode: color;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
       }
        span{
            color: $text-color13;
            padding: 119px 30px;
            display: block;
            text-align: center;
            font-size: 50px;
            font-style: normal;
            font-weight: 500;
            @media (max-width: 767px) {
                font-size: 30px;
            }
        }
    }
}

.cmsbody{
    padding-top: 50px;
    padding-bottom: 100px;
}

.cmstit{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 20px;
    color: $text-color13;
}

.cmscnt{
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    color: $text-color;
    line-height: 27px;
    p{
        margin-bottom: 24px;        
    }
    ul{
        list-style-type: disc;
    padding-left: 45px;
    margin-bottom: 24px;
        li{
            font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
        }
    }
}
.cmscnt + .cmstit{
    margin-top: 40px;
 }

