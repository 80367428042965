// banner  start
.bnrSldr {
   img
   {
      display: inline-block;
   }
   .slick-list
   {
      transition: 0.25s height;
   }

   .slick-track {
      @media only screen and (min-width: 992px)
      {
         display: flex;
         align-items: center;
      }
   }
}

.bnrSldr  .slick-dots {
   z-index: 9;
   position: relative;
   bottom: 0px;
   font-size: 0px;
   margin-bottom: 1rem;
   @media only screen and (max-width: 1199px)
   {
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
   }
}

.bnrSldr .slick-dots li button {
   width: 10px;
   height: 2px;
   background-color: rgba(255,255,255,0.3);
   padding: 0px;
   display: inline-block;
}

.bnrSldr .slick-dots li button::before {
   display: none;
}

.bnrSldr .slick-dots li {
   font-size: 0px;
   height: auto;
   text-align: center;
   width: auto;
   margin: 0px 2px;
}

.bnrSldr .slick-dots li.slick-active button {
   background-color: #ffffff;
   width: 16px;
}

.bnrSldIg {
   @media only screen and (max-width: 991px)
   {
      margin-bottom: 1.5rem;
      margin-top: 1rem;
   }
}
// banner End


// Sites Content Start
.secpad-top
{
   overflow: hidden;
}
// Sites Content End



// Join Growing Slider Start
.joinSldrBtns .btn {
   width: 52px;
   height: 52px;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   background-color: #ffffff;
   border-radius: 0px;
   margin-right: 10px;
}

.joinSldrBtns {
   padding-top: 1.5rem;
}

.joinSldrBtns .btn:hover {
   background-color: #000000;
}

.joinSldrBtns .btn:hover img {
   filter: brightness(20);
}


.hmecardslideCnt
{
   @media only screen and (min-width: 1025px)
   {
      padding-right: 24px;
   }
}
// Join Growing Slider End


// Team Section Start
.teamSec
{
   overflow: hidden;
}
// Team Section End