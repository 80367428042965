$body-bg: #ffffff;
$body-color: #595959;
$border-radius: 0.425rem;
$border-width: 1px;
$font-family-base: 'D-DIN-PRO', sans-serif;
$font-size-base: 1rem;

$secondary-font: $font-family-base;

$primary-gradient-color:  linear-gradient(93.21deg, #BB00FF -4.32%, #00E0FF 100%);
$border-color-gradient: linear-gradient(92.49deg, #A718FF 0.96%, #04DAFF 99.2%);
$primary-color: #1228B2;
$text-white: #ffffff;
$text-color: #595959;
$text-black: #000000;
$text-color13: #131313;
$footerbg: #181818;


$border-color: rgba(255,255,255,0.1);

$light-text: #ADA2CC;
$text-black3: #312353; 
$secondary-color: #3B306A;

$bg-header: #202020;

$dark-bg-0: #0B0B0B;
$text-gray: #999999;
$text-gray2: #888888;
$light-bg: #F4F5FA;

// $secondary-light: #CFE2FF;
$text-green: #29EE60;
$text-red: #F01C1C;
// $secondary-border-color: #B6D4FE;
$gray-text: #777777;


// $text-dark: #084298;



// $primary-border-color: #2d1481;

 
 //$light-text2: #898989;



// $text-color-50:rgba(8,0,34,0.5);
// $bg-gray-color: #e7e7e7;
// $profileimg: linear-gradient(180deg, #663ebb 100%, #663ebb 0%);


$theme-colors: (
     "primary": $primary-color,
     "secondary": #441cff,
     "light": #f4f5fa,
    // "danger": #ff4136,
);

 :root{
     --primary-gradient: radial-gradient(60.67% 61.22% at 54.53% 42.49%, #1A0351 0%, #07071C 95.31%);    
//     --primary: #a600ff;
//     --bg-header: #f2f2f2;
//     --text-color: #080022;
//     --text-white: #ffffff;
//     --border-color: #e6e5e8;    
 }