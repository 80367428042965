@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.0/font/bootstrap-icons.css");
@import url("https://pro.fontawesome.com/releases/v5.15.4/css/all.css");


@font-face {
    font-family: 'D-DIN-PRO';    
    font-weight: 400;
    src: url('../fonts/D-DIN-PRO-400-Regular.otf') format('opentype');
}
@font-face {
    font-family: 'D-DIN-PRO';    
    font-weight: 500;
    src: url('../fonts/D-DIN-PRO-500-Medium.otf') format('opentype');
}
@font-face {
    font-family: 'D-DIN-PRO';    
    font-weight: 600;
    src: url('../fonts/D-DIN-PRO-600-SemiBold.otf') format('opentype');
}
@font-face {
    font-family: 'D-DIN-PRO';    
    font-weight: 700;
    src: url('../fonts/D-DIN-PRO-700-Bold.otf') format('opentype');
}
@font-face {
    font-family: 'D-DIN-PRO';    
    font-weight: 800;
    src: url('../fonts/D-DIN-PRO-800-ExtraBold.otf') format('opentype');
}
@font-face {
    font-family: 'D-DIN-PRO';    
    font-weight: 900;
    src: url('../fonts/D-DIN-PRO-900-Heavy.otf') format('opentype');
}


$font-sizes: (10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 22, 24, 25, 26, 28, 30, 32, 34, 36, 38, 40);
@each $size in $font-sizes {
    .fs-#{$size} {
        font-size: #{$size}px !important;        
    }
}

$font-weight: (100, 300, 400, 500, 600, 700, 800, 900);
@each $weight in $font-weight {
    .fw-#{$weight} {
        font-weight: #{$weight} !important;
    }
}

$line-heights: (10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 22, 24, 25, 26, 28, 30, 32, 34, 36, 38, 40);
@each $height in $line-heights {
    .lh-#{$height} {
        line-height: #{$height}px !important;        
    }
}


@import '../scss/theme-light.scss';


